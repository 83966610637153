.ui.vertical.sidebar.menu {
  > .item {
    border-top: solid 1px #99ca34;
  }
}

.ui.top.sidebar.mobilemenu {
  background-color: #000000;
}

.sidebar .servicelink {
  border-top: 1px solid #99ca34;

  a {
    color: #dcfa9f;
  }
}
